import React from 'react'
import { Link, graphql } from "gatsby"

import Layout from '../components/ui/layout'
import Seo from '../components/ui/seo'
import Header from '../components/page/header'

import { random } from '../components/util/random'



const pageTitle = "全記事の一覧"

const Article = ( { postTitle, linkTo } ) => (
  <li className="my-3 ml-2 leading-snug">
    <Link to={linkTo} className="no-underline hover:underline">{postTitle}</Link>
  </li>
)

const PostList = ( { category, catSlug, catData } ) => (
  <section className="pt-36">
    { category !== 'post'
      ? <h2 className="text-2xl md:text-3xl leading-snug">{category}</h2>
      : null
    }
    <ul className="my-4 ml-4 list-disc text-sm">
      { catData.map( ( { node } ) => (
        <Article
          postTitle={node.frontmatter.title}
          linkTo={`/${node.slug}/`}
          key={random()}
        />
      ) ) }
    </ul>
  </section>
)

const Archives = ({data, location}) => {
  const posts = data.posts.edges
  const catsMeta = data.site.siteMetadata.categories
  let cats = {}
  posts.forEach( ( { node } ) => {
    const catSlug = node.slug.split( '/' )[0]
    if ( catSlug in cats ) {
      cats[ catSlug ].totalCount++
    } else {
      let catLabel
      catsMeta.forEach( catMeta => {
        if ( catSlug === catMeta.slug ) {
          catLabel = catMeta.label
          return
        }
      } )
      cats[ catSlug ] = { totalCount: 1, label: catLabel }
    }
  } )
  const arrCatsKeys = Object.keys( cats ).sort( function( a, b ) {
    if( a.label < b.label ) return 1
    if( a.label > b.label ) return -1
    return 0
  })

  return (
    <Layout>
      <Seo
        pageTitle={pageTitle}
        pageDescription="Investment Tech Hackの全記事の一覧"
        pageUrl={location.pathname}
        isNoIndex={true}
      />
      <Header pageTitle={pageTitle} >
        投稿と連載の一覧
      </Header>
      <main>
        <div className="block max-w-screen-sm px-4 md:px-0 mx-auto">
          { arrCatsKeys.map( catSlug => {
            const catLabel = cats[ catSlug ].label
            const catData = posts.filter( ( { node } ) => ( node.slug.split( '/' )[0] === catSlug ) )
            return ( catSlug && catData
              ? <PostList category={catLabel} catSlug={catSlug} catData={catData}  />
              : null
            )
          })}
        </div>
      </main>
    </Layout>
  )
}



export const query = graphql`
  query {
    posts: allMdx(
      sort: {fields: frontmatter___title, order: ASC},
      filter: {frontmatter: {
        draft: {eq: false}
      }}
    ) {
      edges {
        node {
          slug
          frontmatter {
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        categories {
          label
          slug
        }
      }
    }
  }
`

export default Archives
